import React from 'react'

interface DocumentPreviewProps {
  fileUrl: string | undefined
  fileType: 'pdf' | 'image' | undefined
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ fileUrl, fileType }) => {
  if (!fileUrl) return null

  return (
    <div style={{ margin: 20, height: 250 }}>
      {fileType === 'pdf' && (
        <iframe
          src={fileUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title="Prévisualisation du document pdf"
        />
      )}

      {fileType === 'image' && (
        <img
          src={fileUrl}
          alt="Prévisualisation de l'image"
          style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', display: 'block', margin: '0 auto' }}
        />
      )}
    </div>
  )
}

export default DocumentPreview
