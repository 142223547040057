import { FormikTouched } from 'formik'
import { useEffect, useState } from 'react'
import { fetchBusinessData } from 'src/services/business-data'
import { ISubscribeFields } from '.'
import { fetchAllSubscriptionsComplete, Subscription } from 'src/services/subscribe'
import { getJwtToken } from 'src/utils/session'

const useBusinessDataAutoFill = (
  values: ISubscribeFields,
  touched: FormikTouched<ISubscribeFields>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  enabled: boolean
) => {
  const [pending, setPending] = useState(false)
  const [clientExists, setClientExists] = useState(false)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [erroEllipro, setErroEllipro] = useState(false)

  const loadBusinessData = async (businessCode: string, country: 'FR' | 'BE') => {
    setPending(true)
    setErroEllipro(false)

    try {
      const response = await fetchBusinessData(businessCode)

      if (response.code !== 200 || !response.data) {
        setErroEllipro(true)
        throw new Error()
      }

      const result = response.data

      const assignValue = (field: string, value: any) => {
        if (value) {
          setFieldValue(field, value)
        }
      }

      assignValue('name', result.name)

      if (country === 'BE') {
        assignValue('naf', result.activityCode)
      } else {
        assignValue('ape', result.activityCode)
      }

      if (result.managers.length > 0) {
        const processedManagers = result.managers.map((manager) => ({
          firstname: manager.firstname || '',
          lastname: manager.lastname || '',
          position: manager.position || '',
        }));
      
        // Assignation de tous les managers transformés au champ `managers`
        assignValue('managers', processedManagers);
      
        // Gestion des cas en fonction du nombre de managers
        if (processedManagers.length === 1) {
          // Si un seul manager, assigner directement les valeurs au formulaire
          assignValue('job', processedManagers[0].position);
          assignValue('firstname', processedManagers[0].firstname);
          assignValue('lastname', processedManagers[0].lastname);
          setFieldValue('isSeveralManagers', false); // Désactiver la sélection multiple
        } else {
          // Si plusieurs managers, activer la sélection multiple
          setFieldValue('isSeveralManagers', true);
        }
      }

      assignValue('businessDescription', result.activity)
      assignValue('address', result.address)
      assignValue('addressNumber', result.addressNumber)
      assignValue('city', result.city)
      assignValue('zipCode', result.zipCode)
    } catch (error) {
      console.log({ error })
    }

    setPending(false)
  }
  const userLoggedToken = getJwtToken()

  useEffect(() => {
    if (userLoggedToken) {
      const fetchAllClientInCompleted = async () => {
        const allClients = await fetchAllSubscriptionsComplete()
        setSubscriptions(allClients)
      }
      fetchAllClientInCompleted()
    }
  }, [])

  const checkIfClientExists = (sirenOrTva: string) => {
    try {
      if (/^[0-9]{9}$/.test(sirenOrTva)) {
        return subscriptions.some((sub) => sub.siren === sirenOrTva)
      }
      if (/^BE[0-9]{10}$/.test(sirenOrTva)) {
        return subscriptions.some((sub) => sub.tva === sirenOrTva)
      }
      return false
    } catch (error) {
      return false
    }
  }
  useEffect(() => {
    if (enabled && values.tva && /^BE[0-9]{10}$/i.test(values.tva)) {
      //vérifier si on trouve la tva dans la bdd puis contacter Ellipro sinon
      const exist = checkIfClientExists(values.tva)
      setClientExists(exist)
      if (!exist) {
        loadBusinessData(values.tva, 'BE')
      }
    }
  }, [values.tva])
  useEffect(() => {
    if (enabled && values.siren && /^[0-9]{9}$/i.test(values.siren)) {
      //vérifier si on trouve le siren dans la bdd puis contacter Ellipro sinon
      const exist = checkIfClientExists(values.siren)
      setClientExists(exist)
      if (!exist) {
        loadBusinessData(values.siren, 'FR')
      }
    }
  }, [values.siren])

  return {
    pending,
    clientExists,
    erroEllipro,
  }
}

export default useBusinessDataAutoFill
