export interface IBusinessDataResponse {
  code: number
  data?: {
    name: string
    activityCode: string
    activity: string
    managers: {
      firstname: string | null
      lastname: string | null
      name: string | null
      position: string | null
    }[]
    address: string
    addressNumber: string
    city: string
    zipCode: string
    country: string
  }
}

export const fetchBusinessData = async (businessCode: string): Promise<IBusinessDataResponse> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-business-data/${businessCode}`)
    if (!response.ok) {
      throw new Error('siren ou tva incorrect)')
    } else {
      return await response.json()
    }
  } catch (error) {
    console.error('Erreur:', error)
    return {
      code: 500,
      data: undefined,
    }
  }
}
