import { ISubscribeFields } from '.'

type GetManagerRes = {
  isSeveralManagers: boolean
  selectedManager: {
    firstname: string | null
    lastname: string | null
    name: string | null
    position: string | null
  } | null
}

export const getManager = (
  values: ISubscribeFields,
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
): GetManagerRes => {
  const isSeveralManagers = !!values.managers && values.managers.length > 1

  let selectedManager = values.manager ? JSON.parse(values.manager) : null
  if (isSeveralManagers && !selectedManager && values.managers) {
    if (setFieldValue) setFieldValue('manager', JSON.stringify(values.managers[0]))
    selectedManager = values.managers[0]
  }

  return { isSeveralManagers, selectedManager }
}
