import React from 'react'
import { Field } from 'formik'
import { useField, FieldHookConfig } from 'formik'
import './styles.css'

interface IInputProps {
  name: string | FieldHookConfig<any>
  placeholder?: string
  as?: string
  required?: boolean
  full?: boolean
  label?: string
  type?: string
  autocomplete?: string
  onChange?: Function
  children?: any
  rows?: number
  maxLength?: number
  resizeNone?: boolean
  value?: any
  icon?: any
  checked?: boolean
  onClick?: () => void
  style?: any
  className?: any
  disabled?: boolean
}

const Input: React.FC<IInputProps> = ({
  name,
  value,
  placeholder,
  label,
  as,
  type,
  full = false,
  required = false,
  autocomplete,
  children,
  rows,
  maxLength,
  resizeNone = false,
  icon,
  checked,
  onClick,
  style,
  className,
  disabled,
  ...props
}) => {
  let [field, meta] = useField(name)

  const error = meta.error

  return (
    <div className={`field ${full ? 'full' : ''} ${type === 'checkbox' ? 'chk' : ''} ${className}`}>
      {label && type !== 'checkbox' && (
        <label htmlFor={`uni_${name}`}>
          {label}
          {required ? '*' : ''}
        </label>
      )}
      <Field
        id={`uni_${name}`}
        name={name}
        type={type}
        as={as}
        className={`${error && !disabled ? 'error' : ''} ${resizeNone ? 'resize-none' : ''} ${
          disabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
        placeholder={placeholder}
        autoComplete={autocomplete}
        rows={rows}
        maxLength={maxLength}
        onClick={onClick}
        style={style}
        disabled={disabled ?? false}
        {...(value ? { value } : {})}
      >
        {as && as === 'select' && children}
      </Field>
      {(label || children) && type === 'checkbox' && (
        <label htmlFor={`uni_${name}`}>
          {label}
          {children}
          {required ? '*' : ''}
          {error && meta.touched && <div style={{ color: '#d22630' }}>{error}</div>}
        </label>
      )}
      {type !== 'checkbox' && error && meta.touched && <div style={{ color: '#d22630' }}>{error}</div>}
    </div>
  )
}

export default Input
